.answersBlock{
 display: flex;

}
.answersBlock div p {
margin: 0;
    padding: 0;
}
.answersBlock .answersPoints{
    margin-left: 10px;
}

.cardInfo div h1{
    margin: 0 0  10px 0!important;
    padding: 0;
    text-align: left;
    color: rgba(208, 208, 208, 0.88);
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;

}
.cardExtra{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}
.cardInfo{
    display: flex;
    justify-content: space-between;
}
.actions h1{
    text-align: end !important;
    white-space: nowrap;
}
.cardInfoBlock :global(.ant-image), .answersPicture :global(.ant-image){
    width: 100px!important;
    margin: 10px;
}

.cardInfo .edit{
    color: #ff8400;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.cardInfo .delete{
    color: red;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}


.btn_text span{
    font-size: 16px;
    font-weight: 500;
    color: white;
}
.collapse :global(.ant-collapse-header-text){
    color: white;
    font-size: 16px;
    /* letter-spacing: 0; */
    line-height: 20px;
    font-weight: 600;
}


