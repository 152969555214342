.editorClassName{
min-height: 300px;


}
.toolbarClassName{
    border-radius: 6px 6px 0 0 ;
}
.wrapperClassName{
    background-color: #212123;
    border-radius: 6px;

}