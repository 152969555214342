.card {
    border-radius: 6px;
    background-color: #212123;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100% !important;

}
        
.cardCollapse{
    background: red !important;
}
.margin{
    margin: 20px 0;
}
.margin :global(.ant-collapse-header-text){
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
    color: #777777;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;

}
.margin :global(.ant-collapse-expand-icon){
    color: #ff8400;

}
.number{
    padding-left: 5px ;
    color: #ff8400;
    white-space: nowrap; /* Запрещаем перенос строк */
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
}