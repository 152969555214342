.answers {

    border: 1px #ff8400 solid;
    padding: 20px;
    border-radius: 6px;
}
.card{
    margin: 20px;
}
.card :global(.ant-form-item-explain-error){
    position: absolute;
}

.card :global(.ant-space-item) {
    width: 100%;
}

.answersHeader {
    display: flex;
    justify-content: space-between;
}


.btnBlock button:nth-child(2){
    margin-left: 10px;
}
:global(.ant-float-btn-body){
    background: #ff8400 !important;
}
.comment{
    border: 1px #ff8400 solid;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.comment :global(.ant-form-item){
    width: 100%;
    margin-top: 10px;
}