.grIndex {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.main {
    padding: 50px 10vw;

}

.card .title h1 {
    margin: 0 0 5px 0;
    padding: 0;
    font-weight: lighter;
}

.card .title>span {
    color: grey;
    font-weight: normal;
    margin-left: 2.5em;
}

.cardAvatar {
    /*display: flex;*/
    /*justify-content: flex-end;*/
}

.card .cardInfo h1 {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: normal;
    color: #ff8400;
}



.main :global(.ant-collapse) {

    min-width: 300px;
}

.main :global(.ant-collapse-header) {
    align-items: center !important;
}

.main :global(.ant-collapse-header-text) {
    font-size: 22px;
    color: #ff8400;
}

.main :global(.ant-collapse-content-box>p) {
    font-size: 22px;
    color: #ff8400;
}

.cardAvatar img{
margin-top: 10px;
    max-width: 400px !important;
    min-width: 200px !important;
}

.box {
    background-color: rgba(208, 208, 208, 0.02);
    border-bottom: 0;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    min-width: 300px;
}


.header {
    padding: 0 !important;
}
.btnBlock button{
    width: 100%;
    max-width: 300px;
    font-weight: normal;
    letter-spacing: 1px;

}
.btnBlock button:nth-child(1){
    margin-right: 30px;
}
 .btnBlock{
     display: flex;
 }
 .header{
     display: flex;
     align-items: center;
     justify-content: space-between;
 }