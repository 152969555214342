.gameSetting {
    overflow: hidden;
    padding: 0 24px;
}

/*level list*/

.lvlAdd {
    color: #ff8400;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

.lvlAdd span {
    margin-left: 5px;
    font-size: 25px;
}

.title h1 {
    margin: 0;
}

.lvlAddBlock {
    display: flex;
    justify-content: flex-end;
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    height: 100px;
}

.cardPosition {

}

.cardActions {
    display: flex;
    justify-content: flex-end;
}

.cardActions span:nth-child(1) {
    margin-right: 5px;
}

.cardActions span {
    font-size: 18px;
    padding: 5px;
    border-radius: 6px;
    transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.2s, border-color 0.2s;
    cursor: pointer;
}

.cardActions span:nth-child(1):hover {
    background: #1677ff;
    color: white;
}

.cardActions span:nth-child(2):hover {
    background: red;
    color: white;
}



.articleCard {
    padding-bottom: 20px !important;
    padding-top: 5px !important;
    background: #212123;
    border: 1px solid #121213;
    border-radius: 6px;

}

.articleMeta {
    padding: 15px 0;
}

.articleTags {
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    padding: 2px 5px;

}

.articleMeta span {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
