.card input{
    margin-top: 10px;
    height: 47px;
    font-size: 16px;
    border-radius: 6px;
    color: #777777;
    letter-spacing: 0;
    line-height: 24px;
}
.btnBlock button:nth-child(1){
    margin-right: 10px;
}

.switchLabel{
    color: rgba(208, 208, 208, 0.88);
    font-weight: 400;
    font-size: 16px;
}