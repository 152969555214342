.grIndex {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.main {
    padding: 50px 10vw;
}
.extra{
    color: #777777;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin: 10px 0 !important;
}