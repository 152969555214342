.loginBlock{

height: 100vh;

}
.loginBlock>div{
    height: 100%;
}
.btnBlock button:nth-child(1){
    margin-right: 10px;
}
.loginBlock h1{
    color: #ff8400;
}
.signInForm{

    max-width: 440px;
    min-width: 440px;
}