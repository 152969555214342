.grIndex {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.main {
    padding: 50px 10vw;

}

.extraBlock{
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    align-items: center;
}
.extra{
    white-space: nowrap;
    color: #777777;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin:  0 10px !important;
}
