
.loginContainer{
    background:#e0e0e0;
    height: 100vh;
}
.loginContainer>div{
    height: 100%;
}
.signInForm{
    background: black;
    border-radius: 15px;
    box-shadow:  11px 11px 39px #707070,
    -11px -11px 39px #ffffff;
    padding: 20px;
    color: #B9848C;
    max-width: 470px;
    min-width: 460px;
}
.signInForm h1{
    color: #ff8400;

    margin: 0;
    padding: 0;
}
.signInForm label{
    cursor: pointer;
    font-size: 20px;
    letter-spacing: 1px;
}
.login{
    margin: 20px 0;
}
.login :global(.ant-input), .signInForm :global(.ant-input-password) {
    height: 47px;
    border-radius: 6px;
    font-size: 20px;
}
.signInForm button{
    margin: 20px 5px 0px 5px;
}