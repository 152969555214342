@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
html, .grIndex, h1, h2, h3, h4, h5, p,
ul, .tBig, .tSmall, .tXsmall, button {
    font-family: "Roboto", sans-serif;
    font-weight: 300; }

/* @end */
/* @group temporary */
/* @end */
/* @group basic */
* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none; }

.grIndex {
    color: #ffffff;
    height: 100%; }

.landing a:link,
.landing a:active,
.landing a:visited {
    color: #d0d0d0;
    text-decoration: none;
    border-bottom: 1px solid rgba(208, 208, 208, 0.5);
    transition: all .3s; }

.landing a:hover {
    color: #d0d0d0;
    text-decoration: none;
    border-bottom: 1px solid #d0d0d0; }

.landing a.l-local:link,
.landing a.l-local:active,
.landing a.l-local:visited {
    border-bottom: 1px dashed rgba(208, 208, 208, 0.5); }

.landing a.l-local:hover {
    border-bottom: 1px dashed #d0d0d0; }

.landing a.lNo:link {
    border-bottom: none; }

/* @end */
/* @group container */
body{
    background: #212123;
}
.grIndex {
    display: grid;
    grid-template-rows: 114px auto minmax(300px, min-content);
    grid-template-columns: 2% repeat(12, 1fr) 2%;
    grid-column-gap: 2%;
    background-color: #212123; }

.header {
        position: static;
    display: grid;
    grid-template-columns: 2% repeat(12, 1fr) 2%;
    grid-column-gap: 2%;
    align-content: center;
    grid-column: 1 / span 14; }

.landing,
.grStd {
    display: grid;
    grid-template-columns: 2% repeat(12, 1fr) 2%;
    grid-column-gap: 2%;
    grid-column: 1 / span 14;
    align-content: start; }

.footer {

    display: grid;
    grid-template-columns: 2% repeat(12, 1fr) 2%;
    grid-column-gap: 2%;
    grid-column: 1 / span 14;
}

.landing section,
.landing article,
.landing div {
    display: grid;
    align-content: start; }

.grIndex {
    grid-template-rows:  1fr minmax(300px, min-content);
    height: 100%; }

.header  {
    height: 114px;
    grid-row-start: 1;
    z-index: 2; }

.grsOne {
    grid-row-start: 1; }

.gr-block {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2.2%;
    grid-row-gap: 32px;
    grid-column: 2 / span 12;
    grid-auto-flow: row;
    align-content: start; }

.grStd,
.gr-full {
    grid-column: 1 / span 14; }

.gr-c,
.grLc,
.grLcBig,
.gr-center,
.gr-rc-big,
.bBasic > h1,
.bBasic > h2,
.bBasic > h3,
.bBasic > h4,
.bBasic > h5,
.bBasic > p,
.bBasic > ul {
    grid-column: 2 / span 12; }

.gr-rc {
    grid-column: 4 / span 10; }

.grLcSmall {
    grid-column: 2 / span 10; }

/* @group column start */
.gr-cs1 {
    grid-column-start: 1 !important; }

.grCs2 {
    grid-column-start: 2 !important; }

.gr-cs3 {
    grid-column-start: 3 !important; }

.gr-cs4 {
    grid-column-start: 4 !important; }

.gr-cs5 {
    grid-column-start: 5 !important; }

.gr-cs6 {
    grid-column-start: 6 !important; }

.gr-cs7 {
    grid-column-start: 7 !important; }

.gr-cs8 {
    grid-column-start: 8 !important; }

.gr-cs9 {
    grid-column-start: 9 !important; }

.gr-cs10 {
    grid-column-start: 10 !important; }

.gr-cs11 {
    grid-column-start: 11 !important; }

.gr-cs12 {
    grid-column-start: 12 !important; }

/* @end */
/* @group column end */
.gr-ce2 {
    grid-column-end: span 2; }

.gr-ce3 {
    grid-column-end: span 3; }

.gr-ce4 {
    grid-column-end: span 4; }

.gr-ce5 {
    grid-column-end: span 5; }

.gr-ce6 {
    grid-column-end: span 6; }

.gr-ce7 {
    grid-column-end: span 7; }

.gr-ce8 {
    grid-column-end: span 8; }

.gr-ce9 {
    grid-column-end: span 9; }

.gr-ce10 {
    grid-column-end: span 10; }

/* @end */
/* @group rows */
.gr-r2 {
    grid-row-end: span 2; }

.gr-r3 {
    grid-row-end: span 3; }

.grR4 {
    grid-row-end: span 4; }

.gr-r5 {
    grid-row-end: span 5; }

.gr-r6 {
    grid-row-end: span 6; }

.gr-r7 {
    grid-row-end: span 7; }

.gr-r8 {
    grid-row-end: span 8; }

/* @end */
/* @end */
/* @group text */
h1 {
    font-size: 2.9em;
    line-height: 1; }

.bBasic > h1 {
    margin: 16px 0; }

h2 {
    font-size: 2.2em;
    line-height: 1.2; }

h3 {
    font-size: 1.8em;
    line-height: 1.2; }

h4 {
    font-size: 1.4em;
    line-height: 1.3; }

h5 {
    font-size: 1.2em;
    line-height: 1.4; }

p,
ul {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 16px; }

main > h2, h3, h4, h5, p, ul {
    color: #d0d0d0; }

p:last-child,
ul:last-child {
    margin-bottom: 0; }

.tBig {
    font-size: 1.2em;
    line-height: 1.5; }

.tSmall {
    font-size: .85em !important;
    line-height: 1.5; }

.tXsmall {
    font-size: .7em !important;
    line-height: 1.5; }

.t-italic {
    font-style: italic; }

.t-bold {
    font-weight: 500 !important; }

.t-regular {
    font-weight: 400 !important; }

ul {
    list-style-type: none; }

ul.tMdash li {
    position: relative;
    margin-left: 1.3em;
    margin-bottom: 8px; }

ul.tMdash li:last-child {
    margin-bottom: 0; }

ul.tMdash li:before {
    content: "—";
    position: absolute;
    left: -1.3em; }

.t-nowrap {
    white-space: nowrap; }

/* @group line-height */
.t-lh {
    line-height: 1 !important; }

.t-lh1 {
    line-height: 1.1 !important; }

.t-lh2 {
    line-height: 1.2 !important; }

.t-lh3 {
    line-height: 1.3 !important; }

.t-lh4 {
    line-height: 1.4 !important; }

.t-lh5 {
    line-height: 1.5 !important; }

.t-lh6 {
    line-height: 1.6 !important; }

.t-lh7 {
    line-height: 1.7 !important; }

.t-lh8 {
    line-height: 1.8 !important; }

/* @end */
/* @end */
/* @group parameters */



.p-d-grid {
    display: grid; }

.pDBlock {
    display: block; }

.p-d-iblock {
    display: inline-block; }

/* @group margin */
.p-id0 {
    margin-bottom: 0; }

.p-id4 {
    margin-bottom: 4px; }

.pId8 {
    margin-bottom: 8px; }

.p-id12 {
    margin-bottom: 12px; }

.p-id16 {
    margin-bottom: 16px; }

.p-id24 {
    margin-bottom: 24px; }

.pId32,
.pId32v {
    margin-bottom: 32px; }

.p-id48 {
    margin-bottom: 48px; }

.pId64 {
    margin-bottom: 64px; }

.pId80 {
    margin-bottom: 80px; }

.p-id96 {
    margin-bottom: 96px; }

.p-id128 {
    margin-bottom: 128px; }

.p-id192 {
    margin-bottom: 192px; }

.p-id256 {
    margin-bottom: 256px; }

.p-up0 {
    margin-top: 0; }

.p-up4 {
    margin-top: 4px; }

.p-up8 {
    margin-top: 8px; }

.p-up12 {
    margin-top: 12px; }

.p-up16 {
    margin-top: 16px; }

.p-up24 {
    margin-top: 24px; }

.pUp32 {
    margin-top: 32px; }

.p-up48 {
    margin-top: 48px; }

.p-up64 {
    margin-top: 64px; }

.p-up80 {
    margin-top: 80px; }

.pUp96 {
    margin-top: 96px; }

.pUp128 {
    margin-top: 128px; }

.p-up192 {
    margin-top: 192px; }

.p-up256 {
    margin-top: 256px; }

/* @end */
/* @group padding */
.p-id0p {
    padding-bottom: 0; }

.p-id4p {
    padding-bottom: 4px; }

.pId8p {
    padding-bottom: 8px; }

.p-id12p {
    padding-bottom: 12px; }

.p-id16p {
    padding-bottom: 16px; }

.p-id24p {
    padding-bottom: 24px; }

.pId32p {
    padding-bottom: 32px; }

.p-id48p {
    padding-bottom: 48px; }

.pId64p {
    padding-bottom: 64px; }

.pId80p {
    padding-bottom: 80px; }

.p-id96p {
    padding-bottom: 96px; }

.p-id128p {
    padding-bottom: 128px; }

.p-id192p {
    padding-bottom: 192px; }

.p-id256p {
    padding-bottom: 256px; }

.p-up0p {
    padding-top: 0; }

.p-up4p {
    padding-top: 4px; }

.p-up8p {
    padding-top: 8px; }

.p-up12p {
    padding-top: 12px; }

.p-up16p {
    padding-top: 16px; }

.p-up24p {
    padding-top: 24px; }

.pUp32p {
    padding-top: 32px; }

.p-up48p {
    padding-top: 48px; }

.p-up64p {
    padding-top: 64px; }

.p-up80p {
    padding-top: 80px; }

.p-up96p {
    padding-top: 96px; }

.pUp128p {
    padding-top: 128px; }

.p-up192p {
    padding-top: 192px; }

.p-up256p {
    padding-top: 256px; }

/* @end */
/* @group opacity */
.p-op5 {
    opacity: .5;
    filter: alpha(opacity=50); }

.pOp5h {
    opacity: 1;
    filter: alpha(opacity=100);
    transition: all .3s; }

.pOp5h:hover {
    opacity: .5;
    filter: alpha(opacity=50); }

.pOp4ih {
    opacity: .4;
    filter: alpha(opacity=40);
    transition: all .3s; }

.p-op6ih {
    opacity: .6;
    filter: alpha(opacity=60);
    transition: all .3s; }

.pOp4ih:hover,
.p-op6ih:hover {
    opacity: 1;
    filter: alpha(opacity=100); }

/* @end */
/* @group z-index */
.p-zi2 {
    z-index: 2; }

.p-zi3 {
    z-index: 3; }

.p-zi4 {
    z-index: 4; }

.p-zi5 {
    z-index: 5; }

.p-zi6 {
    z-index: 6; }

.p-zi7 {
    z-index: 7; }

.p-zi8 {
    z-index: 8; }

.p-zi9 {
    z-index: 9; }

.p-zi10 {
    z-index: 10; }

/* @end */
/* @group colors */
.c-white {
    color: #ffffff; }

.c-black {
    color: #212123; }

.cGray {
    color: #909090; }

.c-gray-light {
    color: #d0d0d0; }

.c-gray-dark {
    color: #505050; }

.c-teal {
    color: #00b1b3; }

.cOrange {
    color: #ff8400 !important; }

.c-orange-light {
    color: #ff901a; }

.c-orange-dark {
    color: #e67700; }

.c-purple {
    color: #e31557; }

.c-sky {
    color: #2498d4; }

.cYellow {
    color: #ffd406; }

.c-lime {
    color: #7db243; }

.c-blue-gray {
    color: #77909c; }

.c-mustard {
    color: #988323; }

.c-bg-gray {
    background-color: #909090; }

.c-bg-gray-light {
    background-color: #d0d0d0; }

.c-bg-gray-dark {
    background-color: #505050; }

.c-bg-teal {
    background-color: #00b1b3; }

.c-bg-orange {
    background-color: #ff8400; }

.c-bg-yellow {
    background-color: #ffd406; }

.c-bg-purple {
    background-color: #e31557; }

.c-bg-sky {
    background-color: #2498d4; }

.c-bg-lime {
    background-color: #7db243; }

.c-bg-blue-gray {
    background-color: #77909c; }

.c-bg-mustard {
    background-color: #988323; }

/* @end */
/* @end */
/* @group elements */
.eVobyHi img {
    width: 100%;
    max-width: 270px; }

.e-hashtaglist {
    grid-column: 2 / span 12 !important;
    text-align: center; }

.e-hashtaglist > li {
    display: inline-block;
    margin: 0 12px 12px 12px; }

/* @end */
/* @group index */
.iCover {
    background: url("../../images/index/cover_sm.jpg") no-repeat center bottom/cover;
    min-height: 100vh;
    padding-top: 114px;
}

.iSlogan {
    grid-column: 2 / span 9;
    font-size: 1.5em;
    color: #ffffff;
    line-height: 1.16em;
    margin-top: 5vh; }

.iSlogan > span {
    background-color: #ff8400;
    padding: 3px;
    -webkit-box-decoration-break: clone; }

.grIlist {
    display: grid;
    grid-column: 3 / span 10;
    margin-top: 96px;
    margin-bottom: 0; }

.grIlist > li {
    display: grid;
    margin-bottom: 64px; }

.grIlist > li:last-child {
    margin-bottom: 0; }

.grIlist > li img {
    width: 100%;
    max-width: 376px; }

.grIlist > li h3 {
    margin-bottom: 6px; }

.grIlist > li p {
    margin-bottom: 0; }

/* @end */
/* @group forms */
header button, .grIndex button {
    background-color: #ff8400;
    border-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    padding: 10px 24px;
    text-decoration: none;
    font-size: 1em;
    line-height: 1;
    transition: all .3s; }

header button:hover, .grIndex button:hover {
    background-color: #e67700; }

header button:active,.grIndex button:active {
    position: relative;
    top: 1px; }

/* @end */
/* @group modal */
/* @end */
/* @group header */
.hLogo {
    grid-row: 1;
    grid-column: 2 / span 7;
    width: 172px;
    border-bottom: none !important; }

/* @group menu */
.hMenuMobile {
    grid-row: 1;
    grid-column: 11 / span 3;
    align-self: center;
    justify-self: end;
    width: 32px;
    height: 24px;
    cursor: pointer;
    z-index: 5; }

.hMenuMobile div {
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s;
    backface-visibility: hidden; }

.hMenuMobile div.one {
    margin: 1px 0 4px 0; }

.hMenuMobile div.two {
    margin: 4px 0 4px  0; }

.hMenuMobilemobile div.three {
    margin: 4px 0 1px 0; }

.hMenuMobile.on .one {
    transform: rotate(45deg) translate(7px, 7px); }

.hMenuMobile.on .two {
    opacity: 0; }

.hMenuMobile.on .three {
    transform: rotate(-45deg) translate(7px, -7px); }

.hMenuActive{
    display: flex !important;
    flex-direction: column !important;
}
.hMenu {
   display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    align-content: center;
    justify-content: center;
    margin: 0;
    font-size: 1.3em;
    line-height: 2em;
    background-color: #00b1b3;
    text-align: center;
    z-index: 4; }
.logout{
    width: 25px;
    cursor: pointer;
}
.hMenu li a, .logout {
    color: #ffffff;
    border-bottom: none; }

.hMenu li a:hover {
    color: #505050;
    border-bottom: none; }

.hMenu li a .iRating {
    width: 16px;
    height: 14px;
    margin: -5px 6px 0 0;
    display: inline-block;
    vertical-align: middle; }

.hMenu li a .iLogin {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle; }

.hMenu li a #i-rating,
.hMenu li a #i-login {
    transition: all .3s; }

.hMenu li a:hover #i-rating,
.hMenu li a:hover #i-login {
    fill: #505050; }

.hMenu li button {
    margin: 16px 0; }

.h-select {
    color: #505050; }

.h-select #i-rating {
    fill: #505050; }

/* @end */
/* @end */
/* @group footer */
.footer p {
    color: #909090; }

.fMenu {
    display: grid;
    grid-column: 2 / span 12;
    grid-template-columns: auto 1fr;
    margin-bottom: 64px;
    align-self: start; }

.fMenu li {
    margin: 0 32px 0 0; }

.fMenu li:nth-child(1) {
    grid-row: span 2; }

.fMenu li:nth-child(2) {
    margin-top: 5px; }

.fMenu li:last-child {
    margin-bottom: 0; }

.fMenu li svg {
    width: 96px;
    margin-bottom: 0; }

.fMenu li a, .logout {
    font-weight: 400;
    color: #909090;
    border-bottom: none; }

.fMenu li a:hover,
.f-select {
    color: #505050 !important; }

.f-select-submenu {
    border-bottom: none !important; }

.fContact {
    grid-column: 2 / span 12;
    padding-left: 118px;
    margin-bottom: 64px; }

.fContact a,
.fCopyright,
.fCopyright a {
    color: #909090; }

.fSocial {
    height: 24px; }

.fSocial li {
    display: inline-block; }

.fSocial li a svg {
    width: 24px;
    margin-right: 8px; }

.fSocial li a #img-ico-fb,
.fSocial li a #img-ico-yt,
.fSocial li a #img-ico-in,
.fSocial li a #img-ico-tm
.fScona a #img-ico-scona {
    transition: all .3s; }

.fSocial li a:hover #img-ico-fb {
    fill: #3b5998; }

.fSocial li a:hover #img-ico-yt {
    fill: #ff0000; }

.fSocial li a:hover #img-ico-in {
    fill: #cc007a; }

.fSocial li a:hover #img-ico-tm {
    fill: #0088CC; }

.fScona a:hover #img-ico-scona {
    fill: #d70027; }

.fPartners {
    grid-column: 2 / span 12;
    margin-bottom: 64px; }

.fPartners p {
    margin-bottom: 8px; }

.fPlogo li {
    display: inline-block;
    margin: 0 26px 8px 0; }

.fPlogo li:last-child {
    margin-right: 0; }

.fPlogo li svg {
    height: 50px; }

.fCopyright {
    grid-column: 2 / span 10;
    margin-bottom: 32px !important; }

.fSecmenu {
    grid-column: 2 / span 10;
    margin-bottom: 4px !important; }

.fSecmenu li {
    display: inline-block; }

.fSecmenu li:after {
    content: "|";
    margin: 0 0.5em; }

.fSecmenu li:last-child:after {
    content: none; }

.fSecmenu li a {
    color: #909090 !important; }

.fScona {
    grid-column: 12 / span 2;
    grid-row: 4 / span 2;
    align-self: end;
    justify-self: end;
    width: 32px;
    height: 32px;
    padding-bottom: 32px; }

/* @end */
/* @group adaptive change */
@media only screen and (min-width: 540px) {
    .gr-c,
    .grLcBig,
    .bBasic > h1 {
        grid-column-end: span 11; }

    .grLc,
    .bBasic > h2,
    .bBasic > h3,
    .bBasic > h4,
    .bBasic > h5,
    .bBasic > p,
    .bBasic > ul {
        grid-column: 3 / span 10; }

    .grQuote {
        grid-column: 2 / span 11 !important;
        margin: 48px 0 64px 0; }

    .grRc {
        grid-column: 8 / span 6; }

    .grLcSmall {
        grid-column-end: span 6; }

    .gr-rc-big {
        grid-column: 3 / span 11; }

    .hLogo {
        grid-column-end: span 5; }

    .iSlogan {
        grid-column-end: span 7;
        font-size: 1.7em;
        margin-top: 5vh;
        margin-bottom: 250px; }

    .fMenu {
        grid-column: 2 / span 7; }

    .fContact {
        grid-column: 9 / span 5;
        padding: 0;
        margin: 8px 0 64px 0; }

    .fPartners {
        grid-column: 2 / span 7; }

    .fScona {
        grid-row: 3 / span 2; } }
@media only screen and (min-width: 768px) {
    .gr-c,
    .grLcBig {
        grid-column-end: span 10; }

    .grQuote {
        grid-column: 2 / span 10 !important; }

    .grLc,
    .bBasic > h2,
    .bBasic > h3,
    .bBasic > h4,
    .bBasic > h5,
    .bBasic > p,
    .bBasic > ul {
        grid-column-end: span 8; }

    .gr-center {
        grid-column: 3 / span 10; }

    .grRc {
        grid-column: 9 / span 5; }

    .grLcSmall {
        grid-column-end: span 5; }

    .gr-rc-big {
        grid-column: 4 / span 10; }

    .hLogo {
        grid-column-end: span 4; }

    .iCover {
        background: url("../../images/index/cover_md.jpg") no-repeat center bottom/cover;
        min-height: calc(75vh + 114px); }

    .iSlogan {
        grid-column-end: span 6;
        font-size: 2em;
        margin-bottom: 180px; }

    .grIlist > li {
        grid-template-columns: 3fr 7fr;
        grid-column-gap: 5%;
        align-items: center;
        margin-bottom: 32px; }

    .grIlist > li.iListEven {
        grid-template-columns: 7fr 3fr; }

    .grIlist > li.iListEven > div:nth-child(1) {
        grid-column-start: 2; }

    .grIlist > li.iListEven > div:nth-child(2) {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: end; }

    .grIlist > li > div:nth-child(2) {
        max-width: 600px; } }
@media only screen and (min-width: 1024px) {
    .gr-c {
        grid-column-end: span 9; }

    .grLc,
    .bBasic > h2,
    .bBasic > h3,
    .bBasic > h4,
    .bBasic > h5,
    .bBasic > p,
    .bBasic > ul {
        grid-column-end: span 7; }

    .grLcBig {
        grid-column-end: span 9; }

    .gr-center {
        grid-column: 4 / span 8; }

    .grRc {
        grid-column: 10 / span 4; }

    .grLcSmall {
        grid-column-end: span 4; }

    .gr-rc-big {
        grid-column: 6 / span 8; }

    .hLogo {
        grid-column-end: span 3; }

    .hMenuMobile {
        display: none; }

    .hMenu {
        display: grid;
        position: relative;
        grid-column: 5 / span 9;
        align-content: center;
        justify-content: end;
        align-items: center;
        background-color: transparent !important;
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1em;
        grid-template-columns: repeat(6, auto); }

    .hMenu li {
        margin-right: 32px; }

    .hMenu li:nth-last-child(2) {
        margin-right: 16px; }

    .hMenu li:last-child {
        margin-right: 0; }

    .hMenu li button {
        font-weight: 400; }

    .hMenu li button {
        margin-top: 0;
        margin-bottom: 0; }

    .hMenu li a:hover {
        color: #00b1b3; }

    .hMenu li a:hover #i-rating,
    .hMenu li a:hover #i-login {
        fill: #00b1b3; }

    .hMenu li .loginText {
        display: none; }

    .h-select {
        color: #00b1b3 !important; }

    .h-select #i-rating {
        fill: #00b1b3; }

    .fMenu {
        grid-column: 2 / span 9;
        grid-template-columns: repeat(6, auto);
        align-self: start;
        justify-self: left; }

    .fMenu li {
        margin-top: 6px !important;
        margin-bottom: 0; }

    .fMenu li:nth-child(1) {
        grid-row: span 1;
        margin-top: 0 !important; }

    .fMenu li:nth-last-child(1) {
        margin-right: 0; }

    .fContact {
        grid-column: 12 / span 2;
        grid-row: span 2; }

    .fPartners {
        grid-column: 2 / span 9; }

    .pId32v {
        margin-bottom: 0; } }
@media only screen and (min-width: 1280px) {
    .grLc,
    .bBasic > h2,
    .bBasic > h3,
    .bBasic > h4,
    .bBasic > h5,
    .bBasic > p,
    .bBasic > ul {
        grid-column-end: span 6; }

    .grLcBig {
        grid-column-end: span 8; }

    .gr-rc-big {
        grid-column: 7 / span 7; }

    .hLogo {
        grid-column-end: span 2; }

    .iCover {
        background: url("../../images/index/cover_lg.jpg") no-repeat center bottom/cover;
        min-height:calc(70vh + 114px); }

    .iSlogan {
        grid-column-end: span 5;
        font-size: 2.3em;
        margin-bottom: 0; } }
@media only screen and (min-width: 1600px) {
    .grIndex,
    .header ,
    .landing,
    .footer,
    .grStd {
        grid-template-columns: 8% repeat(12, 1fr) 8%; }

    .gr-block {
        grid-column-gap: 2.5%; }

    h1 {
        font-size: 3.1em; }

    h2 {
        font-size: 2.4em; }

    h3 {
        font-size: 1.95em; }

    h4 {
        font-size: 1.55em; }

    h5 {
        font-size: 1.3em; }

    p,
    ul {
        font-size: 1.1em; }

    .tBig {
        font-size: 1.3em; }

    .tSmall {
        font-size: .95em; }

    .tXsmall {
        font-size: .75em; }

    .iCover {
        min-height:calc(68vh + 114px); } }
/* @end */
