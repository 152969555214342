.gameSetting{
    overflow: hidden;
    padding: 0 24px;
}
/*level list*/

.lvlAdd{
    color: #ff8400;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}
.lvlAdd span{
    margin-left: 5px;
    font-size: 25px;
}
.title h1{
    margin: 0;
}
.lvlAddBlock{
    display: flex;
    justify-content: flex-end;
}


.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    height: 100px;
}
.cardPosition{

}
.cardPosition:hover .cardActions{
    opacity: 1;
}

.cardActions{
    margin-bottom: 5px;
    transition: opacity 0.5s ease;
    opacity: 0;
    bottom: 0;
    left: 0;
    text-align: right;
}

.cardActions span:nth-child(1){
    margin-right: 5px;
}

.cardActions span{
    font-size: 18px;
    background: #ff8400;
    padding: 5px;
    border-radius: 6px;
    transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.2s,border-color 0.2s;
    cursor: pointer;

}
.cardActions span:nth-child(1):hover{
    background: #1677ff;
    color: white;
}
.cardActions span:nth-child(2):hover{
    background: red;
    color: white;
}
.extraBlock{
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    align-items: center;
}
.extra{
    white-space: nowrap;
    color: #777777;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin:  0 10px !important;
}
.cardPosition :global(.ant-card){
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    border: none;
}

.collapse :global(.ant-collapse-header-text){
    color: #777777;
    font-size: 16px;
    /* letter-spacing: 0; */
    line-height: 20px;
    font-weight: 600;
}
.collapse :global(.ant-collapse-expand-icon){
    color: #ff8400;

}


/*sales*/
.salesCard{
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    border: none;
}
.salesBlock p{
    color: #777777;
    font-size: 16px;
    /* letter-spacing: 0; */
    line-height: 20px;
    margin: 5px 0;
}