.card input{

    height: 47px !important;
    font-size: 16px !important;

    color: #777777 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
}
.card :global(.ant-input-number){
   width: 100%;
}
.card :global(.ant-select-selector){
    height: 49px !important;
    font-size: 16px !important;
    font-weight: normal!important;
    border-radius: 6px !important;
    color: #777777 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    padding: 6px 10px !important;
}


