.sider:global(.ant-layout-sider){
    width: 250px;
    overflow: auto;
    min-height: 100vh;
    flex: 0 0 250px;
    max-width:250px;
    min-width: 250px;
}
.sider :global(.anticon-left::after) {
    content: "Згорнути";
    vertical-align: 1px;

    font-size: 18px;

    letter-spacing: 0;
    line-height: 30px;
/* display: flex; */

}