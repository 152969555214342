.grIndex {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.main {
    padding: 50px 10vw;

}
.titles{
    color: #d0d0d0;
    text-decoration: none;
    border-bottom: 1px solid rgba(208, 208, 208, 0.5);
    transition: all .3s;
    font-size: 1.5em;
    line-height: 1.5;

    font-family: "Roboto", sans-serif;
    font-weight: 300;
}
.active{
    color: #ff8400 !important;
}
.titles:hover{
    border-bottom: 1px solid white;
    cursor: pointer;
}
.titleName h1{
    font-size: 2.9em;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin: 16px 0;

}
.titleInfo h1{
    font-size: 1.2em;
    line-height: 1.6;
    color: #d0d0d0;
    margin: 0 0 16px 0;
    font-weight: normal;
}
.cardBlock{
    margin-top: 30px;
}
.card :global(.ant-card-meta-title){
    color: #ff8400 !important;
    font-size: 2.2em;
    line-height: 1.2;
}
.card :global(.ant-card-meta-description){
    font-size: 1.2em;
}
.card{
    margin: 10px 0;
}
.card a{
    color: #d0d0d0;
    text-decoration: none;
    border-bottom: 1px solid rgba(208, 208, 208, 0.5);
    transition: all .3s;
}
.card a:hover {
    color: #d0d0d0;
    text-decoration: none;
    border-bottom: 1px solid #d0d0d0;
}
