.adminUsers {
    overflow: hidden;
    padding: 0 24px;
}
.title{
    padding: 0 25px;
    height: 100px;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
}

.description > span {
    color: #777777;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.card{
   border-radius: 6px;
}

.card :global(.ant-card-actions) li{
    margin: 0;
}
.card :global(.ant-card-actions) li span span{
    display: block;
    padding: 12px 0;
}


.alertsAddBlock{
    display: flex;
    justify-content: flex-end;
}
.alertsAdd{
    color: #ff8400;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}


