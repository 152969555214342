.cardHeader{
    margin-bottom: 30px;
}
.cardHeader h1{
    white-space: nowrap;
    margin: 10px!important;
    padding: 0!important;
    text-align: left;
    color: rgba(208, 208, 208, 0.88);
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
}
